@import "../../styles/";

.app {
  @include flexbox();
  @include font-regular();

  height: 100vh;
  width: 100%;
  overflow: auto;

.menu-wrapper {
  @include flexbox();
  width: 100%;
  height: 100%;

  .not-signed-in {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    min-width: 100%;
  }

  .menu-wrapper-middle-column {
    @include flexbox();
    @include flex-direction(column);
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $pale-grey;
    padding: 25px;
    box-sizing: border-box;
  }
}

}
