@import "../../styles/colors";

.error-box {
  position: fixed;
  left: calc(50% - 275px);
  bottom: 70px;
  width: 750px;
  min-height: 60px;
  background-color: $error-box-color;
  border-radius: 5px;
  padding: 15px;
  z-index: 10;
  transform: translateY(-50%);
  transition: transform 200ms, height 0ms ease-in-out;

  .content {
    display: flex;
    align-items: center;
    height: 100%;
    overflow-wrap: anywhere;

    .info {
      margin: 10px;
    }

    .message {
      text-align: left;
      color: $font-black-primary;
    }

    .close {
      padding: 5px;
      margin: 5px 5px 5px auto;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &.hidden {
    visibility: hidden;
    height: 0;
  }
}
