$accent-primary: #f50057;

$tangerine: #F99C22;
$tangerine-20: rgba($tangerine, 0.2);
$tangerine-50: rgba($tangerine, 0.5);
$tangerine-70: rgba($tangerine, 0.7);

$maroon: rgb(185, 50, 48);
$medium-carmine: rgb(185, 50, 48);

$dark-blue: #0B172A;
$dark-blue-90: rgba($dark-blue, 0.8);
$dark-blue-10: rgba($dark-blue, 0.1);
$blue-accent-primary: #1A377F;
$warning-red: rgb(244, 67, 54);
$light-blue: #00CDFF;
$light-blue-20: rgba($light-blue, 0.2);

$light-grey: rgb(222, 222, 222);
$light-dark-grey: rgb(120,120,120);

$green: rgb(39,89,55);
$green-10: rgba($green, 0.2);
$dark-green: rgb(39, 89, 55);
$light-green: #006E5A;
$light-green-20: rgba($light-green, 0.2);

$black: rgb(0, 0, 0);
$primary-black: #00381D;
$black-10: rgba($black, 0.1);
$black-50: rgba($black, 0.5);
$black-bean: rgb(28,30,28);

$charcoal-grey: rgb(51, 51, 51);
$charcoal-grey-80: rgba($charcoal-grey, 0.8);
$shadow-grey: rgb(222, 222, 222);
$pale-grey: rgb(245, 244, 246);
$almost-white: rgb(250, 250, 250);
$white: rgb(255, 255, 255);

$font-black-primary: rgb(30, 28, 30);
$font-black-secondary: rgb(51, 51, 51);

$error-box-color: rgb(219,130,111)
