@import "./colors.scss";

$side-menu-width: 250px;
$single-table-width: clamp(450px, 100%, 1200px);
$table-height: clamp(400px, 70%, 800px);
$default-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$modal-box-shadow: rgba(0, 0, 0, 0.6) 0px 15px 30px;



body {
  margin: 0;
  position: relative;
}

button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}

.MuiButton-containedPrimary {
  background-color: #1A377F;
}

.MuiButton-outlinedSecondary {
  &:hover {
    background-color: #f50057;;
    color: $white;
  }
}

a {
  text-decoration: none;
}

.MuiTextField-root {
  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1.15);
    transform-origin: top left;
    color: $dark-blue;
  }
}

.arrow-down {
  margin-top: 5px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid $font-black-primary;
}

.thin-arrow-up, .thin-arrow-down {
  border: solid black;
  border-width: 2px 0px 0px 2px;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 10px;
  transition: transform 200ms;
}

.thin-arrow-down {
  transform: rotate(45deg) rotateX(180deg) rotateY(180deg);
  -webkit-transform: rotate(45deg) rotateX(180deg) rotateY(180deg);
}

.no-display {
  display: none !important;
}

.default-container {
    @include flexbox();
    @include flex-direction(column);
    padding: 25px;
    height: 100%;
}

.default-content-container {
  @include flexbox();
  @include flex-direction(column);
  padding: 25px;
  margin: 10px 0;
  background-color: $white;
  height: 100vh;
  width: calc(100% - 50px);
  border-radius: 5px;
  min-height: 500px;
}

h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.91;
  letter-spacing: 2px;
  color: $font-black-primary;
  text-transform: uppercase;
}

h2 {
    text-shadow: 0 0 0.6px #0f1e46, 0 0 0.6px #0f1e46;  color: $font-black-secondary;
    font-weight: normal;
    text-transform: none;
    font-size: 18px;
    margin-block-end: 0px;
    letter-spacing: 2px;
    margin: 13px 6px 12px 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

h3 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $font-black-primary;
    margin: 10px 0 8px 0;
}

h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: $font-black-primary;
}

.vertical-line {
  border-left: 1px solid $light-grey;
  height: 130px;
}

.horizontal-line {
  border-top: solid 1px $light-grey;
}

.spinner {
  height: 25px;
  width: 25px;
  border: 5px solid $dark-blue-10;
  border-radius: 50%;
  border-top-color: $dark-blue-90;
  animation: rotate 1s 0s infinite linear forwards;

  @keyframes rotate {
    0%   { transform: rotate(0);      }
    100% { transform: rotate(360deg); }
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 20%;
  left: 0;
  top: 0;
  z-index: 2;
  transition: opacity 200ms linear;

  &.closed {
    opacity: 0;
    height: 0;
  }
}

.center-spinner {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

