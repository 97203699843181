@import "../../../styles";

.main-menu {
  @include flexbox();
  @include flex-direction(column);
  transition: width 250ms ease-in-out;
  height: 100vh;
  width: 255px;
  overflow: hidden;
  background-color: $dark-blue;

  &.closed {
    width: 45px;

    .menu-title-container {
      .menu-title {
        margin-left: 8px;

        .app-name {
          font-size: 0;
          background-color: $white;
          height: 4px;
          width: 25px;
          margin-bottom: 5px;
          border-radius: 20px;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    @include flexbox();
    @include align-items(center);
    @include justify-content(left);

    min-height: 38px;
    width: 225px;
    box-sizing: border-box;
  }

  .separator {
    background-color: $tangerine;
    margin: 15px 0;
    height: 1px;
    min-height: 1px;
  }

  .menu-title-container {
    width: 220px;
    overflow: hidden;
    margin-bottom: 10px;

    .menu-title {
      height: 70px;
      width: 100%;
      font-size: 16px;
      margin: 15px;
      padding: 15px 0 10px 0px;
      font-weight: 500;
      color: $tangerine;
      font-weight: 600;
      text-align: left;

      &:hover::after {
        position: absolute;
        right: 0;
        content: "";
        display: inline-block;
        /* By using an em scale, the arrows will size with the font */
        width: 10px;
        height: 10px;
        border-bottom: 0.2em solid $white;
        border-left: 0.2em solid $white;
        transform: rotate(45deg);
      }

      .app-name {
        text-transform: capitalize;
        line-height: 1.5;
      }

      &:hover {
        color: $white;
        transition: color 200ms linear;
      }
    }
  }

  .logout {
    font-weight: bold;
    margin-top: auto;
    @include justify-content(center !important);
  }

  .menu-item {
    color: $white;
    font-size: 14px;
    border-left: solid 5px transparent;

    svg {
      height: 25px;
      width: 25px;
      margin: 0 15px 0 5px;
    }

    &:focus {
      outline: none;
    }

    &.active {
      color: $white;
      background-color: $tangerine-20;
      border-left: solid 5px $tangerine;
    }

    &:hover {
      cursor: pointer;
      background-color: $tangerine;
      color: black;
      font-weight: bold;
    }

    svg {
      margin-bottom: 5px;
    }
  }

  .disabled {
    color: $light-dark-grey;
    pointer-events: none;

    &:hover {
      cursor: pointer;
      background-color: $dark-blue;
    }
  }
}
